import { Box } from "components/ui/box";
import React from "react";
import StartIcon from "public/star.png";
import Image from "next/image";
import { Description } from "components/ui/Typography";
import FiveStarRating from "public/icons/5-start-rating.png";

const Star = ({
  text,
  visibleOnMobile,
  altText,
}: {
  altText?: string;
  text: string;
  visibleOnMobile?: boolean;
}) => {
  return (
    <Box
      sx={{
        display: {
          xs: visibleOnMobile ? "initial" : "none",
          sm: visibleOnMobile ? "initial" : "none",
          md: visibleOnMobile ? "initial" : "none",
          lg: "initial",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          columnGap: "4px",
          justifyContent: "center",
        }}
      >
        <Image height={16} width={96} src={FiveStarRating} alt={altText} />
      </Box>
      <Description
        sx={{
          color: "#BCBCBC",
          fontSize: "16px",
          marginTop: "6px",
        }}
      >
        "{text}"
      </Description>
    </Box>
  );
};

export const Stars = () => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        columnGap: "12px",
        justifyContent: "space-around",
      }}
    >
      <Star
        altText="A user who recently transitioned from Jasper AI to Junia AI for their content writing needs expressed their satisfaction with Junia AI by giving it a 5-star rating."
        text="Never going back to Jasper"
      />
      <Star
        altText="A customer rated Junia AI 5 stars as the best AI tool for writing articles."
        visibleOnMobile
        text="My go to tool for content creation"
      />
      <Star
        altText={`A customer gave Junia AI a 5-star rating as the best AI Blog Writer they have used.`}
        text="Best AI Article Writer I have used"
      />
    </Box>
  );
};
